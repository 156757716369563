// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("fullcalendar/consumer_calendar.js")
require("fullcalendar/employee_calendar.js")

import "foundation-sites"
import $ from 'jquery';
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';

require("stylesheets/application")

$(document).on('turbolinks:load', function() {
  $(document).foundation()
});

import "controllers"
