import req from 'superagent';
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";

document.addEventListener('turbolinks:load', function() {
  let pathname = window.location.pathname;
  let regex = /employees\/\d+/

  if(regex.test(pathname) === true) {    
    const calendarEl = document.getElementById('employeeCalendar');
    const employee_id = parseInt(window.location.pathname.split('/').pop());

    const calendar = new Calendar(calendarEl, {
      timeZone: 'America/PDT',
      plugins: [ dayGridPlugin, timeGridPlugin ],
      initialView: 'dayGridMonth',
      contentHeight: 'auto',
      displayEventTime: false,

      headerToolbar: {
        left: 'prev,next today',
        height: 'parent',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },

      events: function(info, successCallback, failureCallback) {
        req.get(`/employees/${employee_id}/events`)
          .type('json')
          .query({
            start: info.startStr,
            end: info.endStr,
          })
          .end(function(err, res){

            if(err) {
              failureCallback(err);
            } else {
              successCallback(res.body.events);
            }
          })
        }
      });

    calendar.render();
  }
});
