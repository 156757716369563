import req from 'superagent';
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";

document.addEventListener('turbolinks:load', function() {
  let pathname = window.location.pathname;
  let regex = /consumers\/\d+/

  if(regex.test(pathname) === true) {    
    const calendarEl = document.getElementById('consumerCalendar');
    const consumer_id = parseInt(window.location.pathname.split('/').pop());

    const calendar = new Calendar(calendarEl, {
      timeZone: 'America/PDT',
      plugins: [ dayGridPlugin, timeGridPlugin ],
      initialView: 'dayGridMonth',
      contentHeight: 'auto',
      displayEventTime: false,

      headerToolbar: {
        left: 'prev,next today',
        height: 'parent',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },

      events: function(info, successCallback, failureCallback) {
        req.get(`/consumers/${consumer_id}/events`)
          .type('json')
          .query({
            start: info.startStr,
            end: info.endStr,
          })
          .end(function(err, res){

            if(err) {
              failureCallback(err);
            } else {

              const bli_cap_records = res.body.bli_cap_records;

              const capInfoEl = document.getElementById('capInfoData');
              capInfoEl.innerHTML = '';

              var doc = document;
              var fragment = doc.createDocumentFragment();
              var headers = ['service_codes', 'start_date', 'end_date', 'amount', 'usage', 'balance', 'scheduled_hours', 'available_balance']

              for(var i = 0; i < bli_cap_records.length; i++) {
                var tr = doc.createElement('tr')

                headers.forEach(header => {
                  var td = doc.createElement('td');
                  td.innerHTML = bli_cap_records[i][header];
                  tr.appendChild(td);
                });

                fragment.appendChild(tr);
              }

              capInfoEl.appendChild(fragment);
              
              successCallback(res.body.events);
            }
          })
        }
      });

    calendar.render();
  }
});
