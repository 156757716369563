// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  // TODO: Does this need to be a separate controller? Move to ShiftReflex if possible.

  afterScheduledDuration(element, reflex, noop, reflexId) {
    this.updateScheduledDuration();
  }

  afterAddBliAllocation(element, reflex, noop, reflexId) {
    this.updateScheduledDuration();
  }

  // The Scheduled Pay/Bill Hours are automatically set to the Scheduled Duration
  // if there is only one Shift Consumer Budget Line Item
  updateScheduledDuration() {
    let parent_elem = document.getElementById("shift_consumer_budget_line_items_fields");
    let duration = document.getElementById("shift_scheduled_duration").textContent.split(' ')[0];

    let shift_consumer_blis = parent_elem.getElementsByClassName('shift_consumer_budget_line_item')
    if(shift_consumer_blis.length == 1) {
      document.getElementsByClassName('scheduled_pay_hours')[0].value = duration;
      document.getElementsByClassName('scheduled_bill_hours')[0].value = duration;
    }
  }

  afterActualDuration(element, reflex, noop, reflexId) {
    this.updateActualDuration();
  }

  // The Actual Pay/Bill Hours are automatically set to the Actual Duration
  // if there is only one Shift Consumer Budget Line Item
  updateActualDuration() {
    let parent_elem = document.getElementById("shift_consumer_budget_line_items_fields");
    let duration = document.getElementById("shift_actual_duration").textContent.split(' ')[0];

    let shift_consumer_blis = parent_elem.getElementsByClassName('shift_consumer_budget_line_item')
    if(shift_consumer_blis.length == 1) {
      document.getElementsByClassName('actual_pay_hours')[0].value = duration;
      document.getElementsByClassName('actual_bill_hours')[0].value = duration;
    }
  }

  afterEmployeeSelect(element, reflex, noop, reflexId) {
    this.updateEmployeeSelection(element);
  }

  // Set the hidden field value and add background color for employee
  updateEmployeeSelection(element) {
    let hidden_field = document.getElementById("shift_employee_employer_record_id");
    hidden_field.value = element.id;

    if(document.getElementsByClassName('selected').length == 1) {
      document.getElementsByClassName('selected')[0].className = '';
    }

    element.className = 'selected'
  }
}
